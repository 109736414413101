@media all and (max-width: 1024px) {
}

@media all and (max-width: 768px) {
}

@media all and (max-width: 480px) {
  .model.period-title {
    grid-area: side / side / 2 / link !important;
  }
  .model.period-title .info {
    background-image: none !important;
  }
}
