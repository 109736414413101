@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&family=Rubik:wght@500;700&display=swap");

::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #81818179;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(206, 206, 206);
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(255, 255, 255);
}

:root {
  --period-color: #83928d;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input[type="range"] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
  background: transparent;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: #9c9770;
  border-radius: 25px;
  border: 0px solid #000101;
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 13px;
  width: 13px;
  border-radius: 7px;
  background: #9c9770;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: #9c9770;
  border-radius: 25px;
  border: 0px solid #000101;
}
input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 13px;
  width: 13px;
  border-radius: 7px;
  background: #9c9770;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  border-width: 39px 0;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #9c9770;
  border: 0px solid #000101;
  border-radius: 50px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}
input[type="range"]::-ms-fill-upper {
  background: #9c9770;
  border: 0px solid #000101;
  border-radius: 50px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}
input[type="range"]::-ms-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 13px;
  width: 13px;
  border-radius: 7px;
  background: #9c9770;
  cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #9c9770;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #9c9770;
}
.info.sx-col {
  background-image: linear-gradient(
    -66deg,
    rgba(0, 0, 0, 0) 41%,
    rgba(0, 0, 0, 0.86) 85%
  );
}
.logoMain {
  width: 80px;
  min-width: 80px;
}
.gradientCanvas {
  background: #2d2d2d;
}
.main_period_cont .period_selector:last-child {
  margin-right: 0px;
}
.period-title .info {
  background-image: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.86) 90%
  );
}
.label-pin {
  --tw-drop-shadow: drop-shadow(0 25px 25px rgb(0 0 0 / 0.3));
}
.label-area {
  position: absolute;
  bottom: 2.5rem;
  right: 2rem;
}
.period-title .info {
  min-height: 100%;
  height: -webkit-fill-available;
}

.swiper-pagination-bullet {
  background-color: white !important;
}
.swiper-pagination-bullets {
  bottom: 0 !important;
}

/*TODO Ci sono le media query della barra superiore e laterale verranno implentate*/
/* ----- Media queery ------ */
@media all and (max-width: 1440px) {
  .period_text {
    font-size: 1.5rem;
    height: 50px;
  }
  .layer_selector {
    width: 50px;
    height: 50px;
  }
  .layer_selector svg {
    width: 1.5rem;
  }
  .period-title h2 {
    font-size: 1.6rem;
  }
  .period-title .sx-col p {
    font-size: 1.1rem;
    line-height: 1.2rem;
  }
  .button_tm {
    height: 50px;
  }
  .period-title .sx-col {
    min-width: initial;
    width: 45%;
    min-width: 500px;
  }
  .period-title .description {
    padding-right: 0px;
  }
  .period-title .col-map h2 {
    font-size: 1.875rem;
  }
}
@media all and (max-width: 1280px) {
  .layer_selector {
    width: 44px;
    height: 44px;
  }
  .layer_selector img {
    width: 26px;
  }
  .period_selector {
    margin-right: 25px;
  }

  .period_text {
    font-size: 18px;
    height: 44px;
    padding: 0px 8px;
    line-height: 17px;
  }

  .modal-dialog {
    max-width: 1000px;
  }
  .logo svg {
    width: 4.5rem;
  }

  .period-title .col-map .description {
    font-size: 1.25rem;
  }
}

@media all and (max-width: 1024px) {
  .inspector_col {
    padding: 25px;
  }

  .period-title p {
    font-size: 1rem;
    line-height: 1.1rem;
  }
  .button_tm {
    height: 45px;
    font-size: 1rem;
  }
  .button_tm svg {
    width: 2.2rem;
  }
  .period-title .info.sx-col {
    min-width: 400px;
    background-image: linear-gradient(
      -66deg,
      rgba(0, 0, 0, 0) 46%,
      rgba(0, 0, 0, 0.86) 90%
    );
  }
  .item-link svg {
    width: 2rem;
  }
  .item-link span {
    font-size: 1rem;
  }
  .period-title .info {
    width: 50%;
    min-width: initial;
  }
  .period-title .col-map h2 {
    font-size: 1.6rem;
  }
  .period-title .col-map .description {
    font-size: initial;
    padding-right: 5rem;
  }
  .action-list .icon svg {
    height: 16px;
  }
  .action-list span {
    font-size: 15px;
  }
  .action-main {
    width: 100%;
  }
  .label-area {
    bottom: 7rem;
  }
}

@media all and (max-width: 768px) {
  .main_period_cont {
    padding: 1rem;
  }
  .period-title .info {
    width: 50%;
    padding: 1rem;
    min-width: initial;
  }
  .period-title h2 {
    font-size: 18px;
    /* margin-bottom: 5px; */
    margin-bottom: 0px;
  }
  .period-title p {
    font-size: 12px;
    line-height: 16px;
  }
  .inspector_col {
    padding: 15px;
    width: 40%;
    min-width: initial;
  }
  .period_selector {
    margin-right: 10px;
  }
  .layer_selector {
    width: 34px;
    height: 34px;
    margin-right: 2px;
  }
  .layer_selector img {
    width: 20px;
  }
  .period_text {
    font-size: 16px;
    height: 34px;
    padding: 0px 8px;
    width: 100%;
  }
  .inspector_col h2 {
    font-size: 18px;
  }
  .title_box {
    line-height: 28px;
    font-size: 13px;
  }
  .nav_item_box_filter ul {
    padding: 5px;
  }
  .filter_item .icon {
    min-width: 4px;
  }
  .filter_item .info {
    font-size: 14px;
    font-weight: 400;
  }
  .information_pin {
    width: 100%;
  }
  .link_pin {
    margin: 15px 0px;
  }
  .link_elem .icon img {
    width: 20px;
    margin-right: 10px;
  }
  .link_elem .info {
    font-size: 15px;
  }
  .link_elem {
    margin-bottom: 8px;
  }
  .link_library {
    margin-top: 20px;
  }
  .link_library .info {
    font-size: 14px;
  }
  .link_library li .icon img {
    height: 12px;
  }
  .link_library li {
    margin-bottom: 4px;
  }
  .modal-body {
    padding: 1rem;
  }
  .modal-title {
    font-size: 15px;
    line-height: 18px;
  }
  .modal-text {
    font-size: 12px;
    line-height: 13px;
  }
  .modal-close {
    width: 30px;
    height: 30px;
  }
  .button_tm {
    height: auto;
    font-size: 0.8rem;
    padding: 0.5rem;
  }
  .button_tm svg {
    width: 1.5rem;
    padding-right: 0px;
  }
  .button_tm span {
    display: none;
  }
  .logo svg {
    width: 60px;
  }
  .logoMain {
    width: 50px;
    min-width: 50px;
  }
  .period-title .sx-col p,
  .period-title .info p,
  .period-title .col-map .description {
    font-size: 13px;
    line-height: 16px;
  }
  .p.description,
  .period-title .col-map .description {
    padding-right: 120px;
  }
  .period-title .sx-col h2,
  .period-title .info h2 {
    font-size: 18px;
    line-height: initial;
  }
  .period-title .info.sx-col {
    background-image: linear-gradient(
      -90deg,
      rgba(0, 0, 0, 0) 20%,
      rgba(0, 0, 0, 0.86) 74%
    );
  }
  .item-link svg {
    width: 1.5rem;
  }
  .item-link span {
    font-size: 13px;
  }
  .main_grid {
    height: -webkit-fill-available;
  }
  .period_text {
    font-size: 14px;
    height: 33px;
    padding: 0px 6px;
    border: 2px solid;
  }
  .layer_selector {
    width: 33px;
    height: 33px;
  }
  .layer_selector svg {
    width: 1rem;
  }
  .progress {
    margin: 0px 0px !important;
  }
  .action-list .icon svg {
    height: 14px;
  }
  .action-list span {
    font-size: 13px;
    line-height: 17px;
  }
  .action-main .pin-title {
    margin: 5px 0px;
    font-size: 15px;
    line-height: 15px;
  }
  .item-group {
    margin-bottom: 15px;
  }
  .modal-body {
    min-height: 100%;
    overflow: auto;
  }
}
@media all and (max-width: 480px) {
  .period-title .info {
    width: 100%;
  }

  .period-title .sx-col p,
  .period-title .info p,
  .period-title .col-map .description {
    padding-right: 0px;
  }

  .main_grid {
    grid-template-rows: auto 10vh 24vh !important;
  }
  .main_period_cont {
    justify-content: flex-end;
    align-items: flex-start;
    gap: 5px;
    flex-direction: column;
  }
  .period-title .info {
    background-image: none;
    height: auto;
    background: rgba(0, 0, 0, 0.7);
    min-width: initial;
    height: auto;
  }
  .period-title .sx-col p,
  .period-title .info p,
  .period-title .col-map .description {
    margin-bottom: 0px;
  }
  .main_period {
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.8) 50%
    );
  }
  .modal-dialog {
    height: -webkit-fill-available;
    min-width: 100%;
  }

  .period-title .info {
    min-height: auto;
    height: auto;
  }
  .info:first-child .animItem {
    margin-top: 15px;
  }
  .period-title {
    z-index: -1;
  }
  .period-title .info.sx-col {
    min-width: initial;
  }
  .panorama.period-title {
    grid-area: side / side / 2 / link !important;
  }
  .panorama.period-title .info {
    background-image: none !important;
  }
  .modal-text {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
  }
  .label-area {
    bottom: 1rem;
  }
}
